import { iter } from "./reusables.js";
import { groupBy } from "./group.js";
import isEqual from "fast-deep-equal";
import isUndefinedVal from "@/utils/isUndefinedVal.js";
const __rows__ = "__rows__";
const __columns__ = "__columns__";

const defaultOptions = () => ({
  index: "key",
});

export default class Pandas {
  constructor(rows, columns, options = {}) {
    this.__rows__ = new Set(rows || []);
    this.__columns__ = this.__clone(columns || []);
    this.__options__ = { ...defaultOptions(), ...this.__clone(options || {}) };
    this.__data__ = {};
  }

  __clone(val) {
    return val;
  }

  __newInstance__(data, columns) {
    if (!this._columnsAreEquals(columns)) {
      return new Pandas(data, columns, this.options);
    }

    if (data.length) {
      const firstRowColumns = Object.keys(data[0]);
      if (!isEqual(firstRowColumns, this[__columns__], true)) {
        return new Pandas(data, firstRowColumns, this.options);
      }
    }

    const newInstance = new Pandas([], [], this.options);
    newInstance[__rows__] = [...data];
    newInstance[__columns__] = [...columns];
    return newInstance;
  }

  _columnsAreEquals(columns, columns2 = this[__columns__]) {
    return isEqual(columns, columns2);
  }

  createDataMap() {
    this.__data__ = {};

    setTimeout(() => {
      this.getRows().forEach((row) => {
        this.__data__[row[this.__options__.index]] = row;
      });
    });
  }

  toCollection() {
    return [...this.__rows__];
  }

  getRows() {
    return [...this.__rows__];
  }

  findIndex(condition) {
    const func =
      typeof condition === "object"
        ? (row) =>
            Object.entries(condition)
              .map(([column, value]) => Object.is(row[column], value))
              .reduce((p, n) => p && n)
        : condition;
    let __index__ = -1;
    iter(
      this.getRows(),
      (row, i) => (func(row, i) ? (__index__ = i) : false),
      () => __index__ > -1
    );
    return __index__;
  }

  setRow(index, cb) {
    const rows = this.getRows();
    const row = rows[index];
    row.set = (col, value) => {
      row[col] = value;
      return this.__newInstance__(rows, this[__columns__]);
    };
    cb(row);
    return this;
  }

  count() {
    return this.__rows__.size;
  }

  /**
   * Return a Row by its index.
   * @param {Number} [index=0] The index to select the row.
   * @returns {Row} The Row.
   * @example
   * df2.getRow(1)
   */
  getRow(index = 0) {
    return this.getRows()[index];
  }

  getTime(time) {
    if (time) new Date(time).getTime();
    return "";
  }

  _isNumber(valToCheck) {
    return typeof valToCheck === "number" && !isNaN(valToCheck);
  }

  _parseValue(val) {
    let parsedVal = "";

    if (this._isDate(val)) {
      parsedVal = new Date(val).getTime();
    } else if (this._isNumber(val)) {
      parsedVal = parseInt(val, 10);
    } else if (this._isString(val)) {
      parsedVal = val.toUpperCase();
    }
    return parsedVal;
  }

  /**
   * Sort DataFrame rows based on column values. The row should contains only one variable type. Columns are sorted left-to-right.
   * @param {String | Array<string>} columnNames The columns giving order.
   * @param {Boolean} [reverse=false] Reverse mode. Reverse the order if true.
   * @param {String} [missingValuesPosition='first'] Define the position of missing values (undefined, nulls and NaN) in the order.
   * @returns {DataFrame} An ordered DataFrame.
   * @example
   * df.sortBy('id')
   * df.sortBy(['id1', 'id2'])
   * df.sortBy(['id1'], [true])
   */
  sortBy(
    columnNames,
    reverse = [],
    transformers = [],
    defaulVals = [],
    missingValuePosition
  ) {
    if (!Array.isArray(columnNames)) {
      columnNames = [columnNames];
    }
    if (!Array.isArray(reverse)) {
      reverse = [reverse];
    }
    const _columnNames = columnNames;
    const _missingValuePosition = {};
    _columnNames.forEach((col, i) => {
      _missingValuePosition[i] = this._missingValuePosition();
    });

    const sortedRows = this.getRows().sort((p, n) => {
      return _columnNames
        .map((col, i) => {
          let pValueToUse = p[col] || "";
          let nValueToUse = n[col] || "";

          if (transformers[i]) {
            pValueToUse = transformers[i](pValueToUse, p);
            nValueToUse = transformers[i](nValueToUse, n);
          }

          if (!pValueToUse && !isUndefinedVal(defaulVals[i])) {
            pValueToUse = defaulVals[i];
          }

          if (!nValueToUse && !isUndefinedVal(defaulVals[i])) {
            nValueToUse = defaulVals[i];
          }
          const pValue = this._parseValue(pValueToUse);
          const nValue = this._parseValue(nValueToUse);

          switch (true) {
            case pValue > nValue:
              return reverse[i] ? -1 : 1;
            case pValue < nValue:
              return reverse[i] ? 1 : -1;
          }
          return 0;
        })
        .reduce((acc, curr) => {
          return acc || curr;
        }, 0);
    });

    if (_columnNames.length > 0) {
      const sortedRowsWithMissingValues = [];
      const sortedRowsWithoutMissingValues = sortedRows;
      sortedRows.forEach((row) => {
        for (const [index, col] of _columnNames.entries()) {
          let rowColVal = row[col];

          if (transformers[index]) {
            rowColVal = transformers[index](rowColVal, row);
          }
          if (!rowColVal && !isUndefinedVal(defaulVals[index])) {
            rowColVal = defaulVals[index];
          }

          if (this._checkMissingValue(rowColVal)) {
            sortedRowsWithMissingValues.push(row);
          }
        }
      });

      let newList = sortedRows;

      if (missingValuePosition === "first") {
        newList = sortedRowsWithMissingValues.concat(
          sortedRowsWithoutMissingValues
        );
      } else if (missingValuePosition === "last") {
        newList = sortedRowsWithoutMissingValues.concat(
          sortedRowsWithMissingValues
        );
      }

      return this.__newInstance__(newList, this[__columns__]);
    }

    return this.__newInstance__(sortedRows, this[__columns__]);
  }

  /**
   * Filter DataFrame rows.
   * @param {Function | Object} condition A filter function or a column/value object.
   * @returns {DataFrame} A new filtered DataFrame.
   * @example
   * df.filter(row => row.get('column1') >= 3)
   * df.filter({'column2': 5, 'column1': 3}))
   */
  filter(condition) {
    const func =
      typeof condition === "object"
        ? (row) =>
            Object.entries(condition)
              .map(([column, value]) => Object.is(row[column], value))
              .reduce((p, n) => p && n)
        : condition;
    const filteredRows = iter(this[__rows__], (row, i) =>
      func(row, i) ? row : false
    );
    return this.__newInstance__(filteredRows, this[__columns__]);
  }

  _missingValuePosition(missingValuePosition) {
    return ["first", "last", "skip"].includes(missingValuePosition)
      ? missingValuePosition
      : "first";
  }

  _isDate(value) {
    return value && typeof value === "object" && value instanceof Date;
  }

  _isString(value) {
    return value && typeof value === "string";
  }

  _checkMissingValue(v) {
    return [NaN, null, undefined, ""].includes(v);
  }

  groupBy(col) {
    return groupBy(this, col);
  }

  /**
   * Create a new subset DataFrame based on given indexs. Similar to Array.slice.
   * @param {Number} [startIndex=0] The index to start the slice (included).
   * @param {Number} [endIndex=this.count()] The index to end the slice (excluded).
   * @returns {DataFrame} The subset DataFrame.
   * @example
   * df2.slice()
   * df2.slice(0)
   * df2.slice(0, 20)
   * df2.slice(10, 30)
   */
  slice(startIndex, endIndex) {
    return this.__newInstance__(
      this.getRows().slice(startIndex || undefined, endIndex || undefined),
      this[__columns__]
    );
  }
}
