export default {
  categories: {},
  globalView: false,
  inboxView: false,
  lastViewChange: "",
  nav: 0,
  projects: {},
  resources: {},
  selectedItems: [],
  taskSettings: {
    statusBtnPosition: "with-task",
  },
  taskTypes: {
    consult: {
      icon: "$consult",
      key: "consult",
      modified: "2021-05-21",
      title: "Call",
    },
    goal: {
      icon: "mdi-calendar-star",
      key: "goal",
      modified: "2021-04-30",
      title: "Goal",
    },
    journal: {
      icon: "$journal",
      key: "journal",
      modified: "2021-06-01",
      title: "Journal",
    },
    message: {
      icon: "$messages",
      key: "message",
      modified: "2021-05-30",
      title: "Message",
    },
    post: {
      icon: "$posts",
      key: "post",
      modified: "2021-05-01",
      title: "Post",
    },
    task: {
      icon: "mdi-calendar-check-outline",
      key: "task",
      modified: "2021-06-04",
      title: "Task",
    },
  },
  tasks: {},
  view: {
    0: {
      groupView: 0,
      selectedDates: [],
      sortMode: 0,
      statusFilter: "scheduled",
      type: "calView",
    },
    1: {
      groupView: 0,
      selectedCategory: "all",
      selectedOptsList: [],
      selectedProject: "all",
      selectedSubcategory: "all",
      sortMode: 0,
      statusFilter: "incomplete",
      type: "treeView",
    },
    2: {
      groupView: 0,
      selectedCategory: "all",
      selectedProject: "all",
      sortMode: 6,
      statusFilter: "incomplete",
      type: "resourceView",
    },
    3: {
      groupView: 0,
      sortMode: 1,
      statusFilter: "incomplete",
    },
    4: {
      sortMode: 0,
      statusFilter: "all",
    },
  },
};
