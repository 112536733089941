<template>
  <div class="actions-wrapper">
    <v-row
      no-gutters
      style="flex-wrap: wrap; row-gap: 8px"
      justify="space-between"
      align="center"
    >
      <v-col cols="auto">
        <v-row dense align="center">
          <v-col cols="auto" v-if="useBeforeControls">
            <slot name="before-controls"></slot>
          </v-col>
          <v-col cols="auto" v-if="showPagination">
            <v-row no-gutters align="center" :class="classesForPaginationRow">
              <v-col cols="auto">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      @click="editPreviousItem"
                      class="task-action-btn item-change-btn"
                      data-action-type="item-change"
                      data-action-value="previous"
                      v-on="on"
                      v-bind="attrs"
                      :disabled="disablePrevBtn"
                      x-small
                    >
                      <v-icon
                        class="main-icon"
                        size="20"
                        :color="paginationBtnIconColor"
                      >
                        mdi-chevron-left
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Previous {{ editItemBtnLabel }}</span>
                </v-tooltip>
              </v-col>

              <v-col cols="auto">
                <span class="count-text">
                  {{ itemNumAndTotalItemCountLabel }}
                </span>
              </v-col>

              <v-col cols="auto">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      @click="editNextItem"
                      class="task-action-btn item-change-btn"
                      data-action-type="item-change"
                      data-action-value="next"
                      v-on="on"
                      v-bind="attrs"
                      :disabled="disableNextBtn"
                      x-small
                    >
                      <v-icon
                        class="main-icon"
                        size="20"
                        :color="paginationBtnIconColor"
                      >
                        mdi-chevron-right
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Next {{ editItemBtnLabel }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="auto" v-if="useBtnRightSide">
            <slot name="bar-btn-right"></slot>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="auto">
        <slot name="center">
          <FormTitle :text="title" />
        </slot>
      </v-col>

      <v-col sm="auto" md="auto" lg="2">
        <v-row no-gutters align="center" justify="end">
          <v-col cols="auto" class="side-btns-wrapper side-btns-wrapper-start">
            <slot name="header-right"> </slot>
          </v-col>

          <v-col cols="auto" v-show="showDivider" class="side-btns-wrapper">
            <div class="btn-divider"></div>
          </v-col>

          <v-col cols="auto" class="side-btns-wrapper">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  @click="handleSave"
                  :class="classesForSaveBtn"
                >
                  <v-icon size="13">$ctiSave</v-icon>
                </v-btn>
              </template>
              <span> Save</span>
            </v-tooltip>
          </v-col>

          <v-col cols="auto" class="side-btns-wrapper side-btns-wrapper-end">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  :color="iconColor"
                  icon
                  @click="handleClose"
                  class="action-btn task-btn-lg action-bar-btn"
                >
                  <v-icon size="13">$ctiCancelIcon</v-icon>
                </v-btn>
              </template>
              <span> Cancel</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { DARK_GREY_ICON_COLOR } from "@/variables/colors";
import { capitalizeFirstLetter } from "@/utils/capitalize";
import FormTitle from "./FormTitle.vue";
import { decrement, increment } from "@/utils/number";
export default {
  components: {
    FormTitle,
  },
  props: {
    title: {
      type: String,
    },
    isEditStarted: {
      type: Boolean,
    },
    editType: {
      type: String,
    },
    currItemIndex: {
      type: Number,
    },
    totalItemCount: {
      type: Number,
    },
    showDivider: {
      type: Boolean,
      default: true,
    },
    useBtnRightSide: {
      type: Boolean,
    },
    useBeforeControls: {
      type: Boolean,
    },
    paginationRowClass: {
      type: String,
    },
    paginationBtnIconColor: {
      type: String,
      default: DARK_GREY_ICON_COLOR,
    },
    showPagination: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    editNextItem(e) {
      this.emitEditItemSelect("next", e);
    },
    editPreviousItem(e) {
      this.emitEditItemSelect("previous", e);
    },
    emitEditItemSelect(type, e) {
      this.$emit("edit-item:select", type, e);
    },
    handleClose(e) {
      this.$emit("close", e);
    },
    handleSave() {
      this.$emit("save");
    },
  },
  computed: {
    classesForSaveBtn() {
      return {
        "action-btn task-btn-lg action-bar-btn": true,
        "save-highlighted": this.isEditStarted,
      };
    },
    editItemBtnLabel() {
      return this.editType ? capitalizeFirstLetter(this.editType) : "";
    },
    iconColor() {
      return DARK_GREY_ICON_COLOR;
    },
    itemNumAndTotalItemCountLabel() {
      let itemIndex = this.currItemIndex;
      let text = "";
      const totalItemCount = this.totalItemCount;

      if (itemIndex === -1) {
        text = "1 of 1";
      } else {
        if (totalItemCount) {
          itemIndex = increment(itemIndex, 1);

          text = `${itemIndex} of ${totalItemCount}`;
        }
      }

      return text;
    },
    disablePrevBtn() {
      return (
        !this.totalItemCount ||
        this.currItemIndex === -1 ||
        this.currItemIndex === 0
      );
    },
    disableNextBtn() {
      return (
        !this.totalItemCount ||
        this.currItemIndex === -1 ||
        this.currItemIndex === decrement(this.totalItemCount, 1)
      );
    },
    classesForPaginationRow() {
      return {
        [this.paginationRowClass]: !!this.paginationRowClass,
      };
    },
  },
};
</script>
<style scoped>
.actions-wrapper {
  margin-top: -1px;
}
.count-text {
  margin-left: 0px;
  margin-right: 0px;
}

.side-btns-wrapper {
  background: #efefef !important;
}

.side-btns-wrapper-start {
  border-radius: 6px 0 0 6px;
}
.side-btns-wrapper-end {
  border-radius: 0 6px 6px 0;
}

.btn-divider {
  height: 20px;
  width: 1px;
  background: #878787;
  margin-left: 4px;
  margin-right: 4px;
}

.action-bar-btn {
  height: 20px !important;
  padding: 5px 8px !important;
  border-radius: 6px !important;
  width: auto !important;
  border-width: 0px !important;
  /* border: 1px solid; */
}

.item-change-btn {
  /* height: 24px !important;
  width: 24px !important; */
}
button.save-highlighted {
  background-color: var(--primary-color) !important;
}

button.save-highlighted >>> .v-icon {
  color: #fff !important;
}
</style>
