import DatabaseStoreInterface from "@/services/DbStoreInterface";
import Vue from "vue";
import createUniqueId from "@/utils/createUniqueId";
import removeProps from "@/utils/removeProps";
import filterList from "@/core/components/DataTable/lib/pandas/filter";
import { getCurrDate } from "./dates";
import store from "@/store";
import { cloneDeep, isEmpty, isPlainObject } from "lodash";
import {
  convertDateTimeStrToDate,
  convertValueToArray,
  emitRefreshList,
  findNodeInTree,
  getAllChildrenOfANode,
  getUniqueName,
} from "./common";
import { ALPHA_NUMERIC_REGEX } from "@/variables/regex";
import {
  RESOURCE_PROPS_FOR_FORM,
  RESOURCE_PROPS_TO_REMOVE,
  RESOURCE_PROPS_TO_RESTORE,
} from "@/variables/resources";
import { resourceTypeLabels } from "@/data/resources";
import { convertHTMLToQlDelta, createOrGetQlInstance } from "./quill";
import { resourceDisplayOptsMap } from "@/data/resourceSettingsConfig";
import { escapeHtmlString } from "@/utils/stringHelpers";
import { RESOURCE_ACTIONS, getUserActions } from "./actionHistory";
import { emptyDescrTypes } from "@/variables/common";
import { findResourceCateDataByRef } from "./resourceCategoriesHelpers";
// import { addOrRemoveOrUpdateResourcesInList } from "@/mixins/resourcesHelpersMixin";

const qlInstance = createOrGetQlInstance();
export const resourcesCollection = new DatabaseStoreInterface(
  "resources-collection"
);

const labels = {
  resources: "Resource",
  categories: "Category",
};
class UserResources {
  userId = "";
  resources = {};
  categories = {};
  selectedCategories = {};
  usedTags = [];
  usedTagsMap = {};
  collapsedGroups = [];
  selectedResourceType = "people";
  internalCollapsedGroups = [];
  constructor(userId) {
    this.userId = userId;
    this.init();
  }
  async init() {
    try {
      const record = await resourcesCollection.getByKey(this.userId);
      if (record.exists) {
        const data = record.data();
        this.resources = data.resources || {};
        this.categories = data.categories || {};
        this.selectedCategories = data.selectedCategories || {};
        this.usedTags = data.usedTags || [];
        this.usedTagsMap = !isEmpty(data.usedTagsMap) ? data.usedTagsMap : {};
        this.collapsedGroups = data.collapsedGroups || [];
        this.selectedResourceType = data.selectedResourceType || "thing";
        this.internalCollapsedGroups = data.internalCollapsedGroups || [];
        return;
      }

      await resourcesCollection.set(this.userId, {
        resources: {},
        categories: {},
        selectedCategories: {},
        usedTags: [],
        usedTagsMap: {},
        collapsedGroups: [],
        selectedResourceType: "people",
        internalCollapsedGroups: [],
      });
    } catch (error) {
      console.error("Unable to init: ", error.message);
    }
  }

  async add(data, type = "resources") {
    try {
      let updates = {};

      this[type] = {
        ...this[type],
        ...data,
      };

      updates[type] = this[type];
      const res = await resourcesCollection.update(this.userId, updates);
      return res;
    } catch (error) {
      console.error(`Unable to add ${labels[type]} :`, error.message);
    }
  }

  async remove(key, type = "resources") {
    try {
      const updates = {};
      delete this[type][key];
      updates[type] = this[type];
      const res = await resourcesCollection.update(this.userId, updates);

      return res;
    } catch (error) {
      console.error(`Unable to remove ${labels[type]} :`, error.message);
    }
  }

  async update(key, data, type = "resources") {
    try {
      const updates = {};

      const currData = this[type][key];
      this[type][key] = {
        ...currData,
        ...data,
        key,
      };

      updates[type] = this[type];
      const res = await resourcesCollection.update(this.userId, updates);
      return res;
    } catch (error) {
      console.error(`Unable to update ${labels[type]} :`, error.message);
    }
  }

  async addMulti(data, type = "resources") {
    try {
      if (!Array.isArray(data)) data = [data];

      const currData = this[type];
      const newData = data.reduce((a, i) => {
        a[i.key] = i;
        return a;
      }, {});
      const updatedItems = {
        ...currData,
        ...newData,
      };
      this[type] = updatedItems;

      const updates = {
        [type]: this[type],
      };
      const res = await resourcesCollection.update(this.userId, updates);
      return res;
    } catch (error) {
      console.error(`Unable to add multiple ${type}: `, error.message);
    }
  }

  async removeMulti(keysToRemove, type = "resources") {
    try {
      if (!Array.isArray(keysToRemove)) keysToRemove = [keysToRemove];
      const currData = this[type];
      this[type] = removeProps(currData, keysToRemove);
      const updates = {
        [type]: this[type],
      };
      const res = await resourcesCollection.update(this.userId, updates);
      return res;
    } catch (error) {
      console.error(`Unable to remove multiple ${type}: `, error.message);
    }
  }

  async set(data, type = "resources", replace) {
    try {
      if (type === "selectedCategories") {
        if (!replace) {
          this[type][data.mode] = data.selection || [];
        } else {
          this[type] = data;
        }

        // if (!Array.isArray(data)) data = [data];
        // this[type] = data;
      } else {
        this[type] = data;
      }

      const updates = {
        [type]: this[type],
      };
      await resourcesCollection.update(this.userId, updates);

      return true;
    } catch (error) {
      console.error(`Unable to set ${type}: `, error.message);
    }
  }

  async setMultiFields(fieldsToSet) {
    try {
      const updates = {};
      fieldsToSet.forEach((fieldData) => {
        if (this[fieldData.key]) {
          this[fieldData.key] = fieldData.value;
          updates[fieldData.key] = fieldData.value;
        }
      });

      await resourcesCollection.update(this.userId, updates);
    } catch (error) {
      console.error(`Unable to set multi fields: `, error.message);
    }
  }

  getResources(type = "map") {
    if (type === "list") {
      return Object.values(this.resources);
    }

    return this.resources;
  }
  getCategories(type = "map") {
    if (type === "list") {
      return Object.values(this.categories);
    }
    return this.categories;
  }
  getSelectedCategories() {
    return this.selectedCategories;
  }

  getUsedTags(resourceType) {
    // if (!isEmpty(resourceType)) {
    //   return this.usedTags[resourceType] || [];
    // }
    // return this.usedTags;
    if (!isEmpty(resourceType)) {
      return this.usedTagsMap[resourceType] || [];
    }
    return this.usedTagsMap;
  }
}

let userResources;

export const initUserResources = (userId) => {
  if (!userResources) {
    userResources = new UserResources(userId);
  }

  return userResources;
};

export const getUserResources = () => {
  if (!userResources) {
    throw new Error("User resources class needs to initialized first!");
  }
  return userResources;
};

export const createResourcesSearchFilter = (query, onlyTag) => {
  const basicQueryProps = {
    type: "like",
    value: query,
  };

  if (onlyTag) {
    return [
      {
        ...basicQueryProps,
        field: "tag",
      },
    ];
  }

  const parsedStr = escapeHtmlString(query);

  return [
    {
      ...basicQueryProps,
      field: "title",
      value: parsedStr,
    },
    {
      ...basicQueryProps,
      field: "descr",
      value: parsedStr,
    },
    {
      ...basicQueryProps,
      field: "tag",
    },
  ];
};

export const createResourceDataForAdd = (
  dataToAdd,
  allResources,
  allCategories,
  checkTag
) => {
  let order = allResources?.length || 0;
  let existingList = allResources || [];
  const catId = dataToAdd.catId || "";
  let tag = dataToAdd.tag || "";
  if (catId && allCategories[catId]) {
    existingList = allResources.filter((r) => r.catId === catId);
    order = existingList?.length || 0;
  }

  if (checkTag) {
    if (!tag) {
      tag = "New_Resource";
    }

    tag = getUniqueName(
      tag,
      allResources,
      undefined,
      undefined,
      "tag",
      (text, index) => {
        return `${text}_${index}`;
      }
    );
  }

  return {
    key: dataToAdd.key || createUniqueId(),
    title: dataToAdd.title || "",
    descr: dataToAdd.descr || "",
    tag: tag || "",
    catId,
    order: dataToAdd.order || order,
    created: dataToAdd.created || getCurrDate("extended"),
    modified: dataToAdd.modified || getCurrDate("extended"),
    type: dataToAdd.type || "",
    showInTree: dataToAdd.showInTree || false,
  };
};

export const createResourcePath = (resourceData, allCategories) => {
  let hashString = "",
    hashOrderString = "";
  if (!resourceData.catId || !allCategories[resourceData.catId]) {
    hashString = "-1";
    hashOrderString = `-1-${resourceData.order}`;
  }

  let paths = [];
  let parentId = resourceData.catId;
  let hashedPaths = [];
  let hashedOrders = [resourceData.order];

  while (parentId) {
    const parent = allCategories[parentId];
    if (!parent) {
      break;
    }
    paths.unshift(parent.name);
    hashedPaths.unshift(parent.order);
    hashedOrders.unshift(parent.order);
    parentId = parent.parentCatKey;
  }

  let createdPath = allCategories[resourceData.catId]?.name;

  if (paths.length) {
    // paths.push("")
    paths = paths.join(" / ");
    createdPath = paths;
    hashedPaths = hashedPaths.join("-");
    hashedOrders = hashedOrders.join("-");
    hashString = hashedPaths;
    hashOrderString = hashedOrders;
  } else {
    createdPath = `/`;
    // hashString = `${hashString}`;
  }

  return {
    hashedPath: hashString,
    hashedOrderedPath: hashOrderString,
    createdPath,
  };
};

export const processResourceData = (
  rawResourceData,
  allCategories = store.getters["resourcesData/categories"]
) => {
  const { createdPath, hashedPath, hashedOrderedPath } = createResourcePath(
    rawResourceData,
    allCategories
  );
  rawResourceData.fullParentCatePath = createdPath;
  rawResourceData.hashedParentCatePath = hashedPath;
  rawResourceData.hashedOrderPath = hashedOrderedPath;
  rawResourceData.createdAsDate = convertDateTimeStrToDate(
    rawResourceData.created
  );
  rawResourceData.modifiedAsDate = convertDateTimeStrToDate(
    rawResourceData.modified
  );
  return rawResourceData;
};

export const processResources = (resources, allCategories) => {
  const clonedResources = cloneDeep(resources);
  return clonedResources.map((n) => processResourceData(n, allCategories));
};

export function createResourceGroupCaptions(data, group) {
  const selectedResourceType =
    store.getters["resourcesData/selectedResourceTypeOpt"];

  let captionToDisplay;
  switch (group) {
    case "hashedParentCatePath":
      captionToDisplay = data[0].fullParentCatePath;
      break;
  }

  if (
    captionToDisplay === "" ||
    !captionToDisplay ||
    captionToDisplay === "/"
  ) {
    captionToDisplay =
      selectedResourceType === "project" ? "No Area" : "No Group";
  }
  return captionToDisplay;
}

export const createResourceCateFilters = (cateList, tree) => {
  let filters = [];
  if (cateList && cateList.length) {
    filters = cateList.reduce((a, id) => {
      let newIds = [];

      if (id !== "root") {
        newIds = [
          {
            field: "catId",
            type: "=",
            value: id,
          },
        ];

        if (!isEmpty(tree)) {
          const nodeRes = findNodeInTree(tree, (node) => node.key === id);

          if (!isEmpty(nodeRes)) {
            const storedCate = nodeRes.node;

            const allCateChildren = getAllChildrenOfANode(storedCate);
            if (!isEmpty(allCateChildren)) {
              const childIds = allCateChildren.map((child) => ({
                field: "catId",
                type: "=",
                value: child.key,
              }));

              newIds.push(...childIds);
            }
          }
        }
      } else {
        newIds = [
          {
            field: "catId",
            type: "=",
            value: null,
          },
          {
            field: "catId",
            type: "=",
            value: undefined,
          },
          {
            field: "catId",
            type: "=",
            value: "",
          },
        ];
      }
      a.push(...newIds);
      return a;
    }, []);
  }

  return filters;
};

export const refreshResourcesList = (reprocessList) => {
  emitRefreshList(reprocessList, "resources");
};
export const addOrUpdateOrRemoveResourcesInLocalList = async (
  {
    resourcesToAdd,
    resourcesToUpdate,
    resourcesToRemove,
    addMethod = "unshift",
    verifyAdd = true,
  },
  refreshList = true
) => {
  const data = {
    addMethod,
    verifyAdd,
  };
  const currCategories = store.getters["resourcesData/categories"];
  const currProcessedResources = store.getters["resourcesData/resources"];

  if (resourcesToUpdate && resourcesToUpdate.length) {
    const resourcesUpdateList = [];
    resourcesToUpdate.forEach((t) => {
      const currIndex = currProcessedResources.findIndex(
        (resource) => resource.key === t.key
      );
      if (currIndex >= 0) {
        resourcesUpdateList.push({
          key: t.key,
          updates: processResourceData({
            ...currProcessedResources[currIndex],
            ...t.updates,
          }),
        });
      }
    });
    data.resourcesToUpdate = resourcesUpdateList;
  }

  if (resourcesToRemove && resourcesToRemove.length) {
    data.resourceIdsToRemove = resourcesToRemove.map((t) => t.key);
  }

  if (resourcesToAdd && resourcesToAdd.length) {
    data.resourcesToAdd = processResources(resourcesToAdd, currCategories);
  }

  await store.dispatch("resourcesData/addOrRemoveOrUpdatesResources", data);

  await Vue.nextTick();
  if (refreshList) {
    refreshResourcesList();
  }
};

export const createUpdateAndEditedResourceDataByCell = ({
  fieldName,
  oldValue,
  currValue,
  rowId,
}) => {
  let updates = {};
  let editActionData = {};
  let changedProps = {};
  if (fieldName === "order") {
    if (typeof currValue === "number") {
      updates[fieldName] = currValue;
      changedProps[fieldName] = currValue;
    }

    if (typeof oldValue === "number") {
      editActionData[fieldName] = oldValue;
    }
  } else {
    updates[fieldName] = currValue || "";
    changedProps[fieldName] = currValue || "";
    editActionData[fieldName] = oldValue || "";
  }

  editActionData.key = rowId;
  editActionData.changedProps = changedProps;
  updates.modified = getCurrDate("extended");
  return {
    updates,
    editedData: editActionData,
  };
};

export const checkIfTagIsInUse = (tagToCheck, resourceType) => {
  tagToCheck = tagToCheck && tagToCheck.trim();
  const userResourcesData = getUserResources();
  const currUsedTags = userResourcesData.getUsedTags(resourceType);
  return currUsedTags.includes(tagToCheck);
};

export const checkIfTagIsAlphaNumeric = (tagToCheck) => {
  tagToCheck = tagToCheck && tagToCheck.trim();

  return ALPHA_NUMERIC_REGEX.test(tagToCheck);
};

export const checkIfTagIsValid = (
  tagToCheck,
  tagLabel = "Tag",
  resourceType
) => {
  tagToCheck = tagToCheck?.trim();

  if (!tagToCheck) {
    return {
      isValid: false,
      error: `${tagLabel} cannot be empty`,
    };
  }
  const isTagAlphacNumeric = checkIfTagIsAlphaNumeric(tagToCheck);
  const tagIsInUse = checkIfTagIsInUse(tagToCheck, resourceType);
  // const tagIsInUse = true;
  if (!isTagAlphacNumeric) {
    return {
      isValid: false,
      error: "Only Aplha numeric strings are valid",
    };
  }
  if (tagIsInUse) {
    return {
      isValid: false,
      error: `${tagLabel} is already in use`,
      subMsg: `Please use a different tag`,
    };
  }

  return {
    isValid: true,
  };
};

export const createResourceTypeOptFilter = (selectedOpt, currFilters) => {
  const localFilters = [...currFilters];
  localFilters.push({
    field: "type",
    type: "=",
    value: selectedOpt,
  });

  return localFilters;
};

export const createUpdateAndEditedResourceData = (
  updatedData,
  currData
  // currRowData,
) => {
  let updates = {};
  let editActionData = {};
  let changedProps = {};

  RESOURCE_PROPS_FOR_FORM.forEach((prop) => {
    if (updatedData[prop] !== undefined) {
      const newVal = updatedData[prop];
      const oldVal = currData[prop];
      if (prop === "order") {
        if (typeof newVal === "number") {
          updates[prop] = newVal;
          changedProps[prop] = newVal;
        }

        if (typeof oldVal === "number") {
          editActionData[prop] = oldVal;
        }
      } else {
        updates[prop] = newVal || "";
        changedProps[prop] = newVal || "";
        editActionData[prop] = oldVal || "";
      }
    }
  });

  editActionData.key = currData.key;

  editActionData.changedProps = changedProps;
  updates.modified = getCurrDate();
  return {
    updates,
    editedData: editActionData,
  };
};

export const searchResourcesByKeyword = (keyword, filtersProps = {}) => {
  let allResources = getUserResources().getResources("list");
  const resourcesCategories = getUserResources().getCategories();
  let { filterByProjectAndCatId, catId } = filtersProps;

  catId = catId?.trim();
  let filters = [];
  if (filterByProjectAndCatId && catId && resourcesCategories[catId]) {
    const resourceCateId = resourcesCategories[catId]?.key;

    if (resourceCateId) {
      filters.push([
        [
          {
            field: "type",
            type: "=",
            value: "project",
          },
          [
            {
              field: "catId",
              type: "=",
              value: resourceCateId,
            },
          ],
        ],
        [
          {
            field: "type",
            type: "!=",
            value: "project",
          },
        ],
      ]);
    }
  }
  const normalisedKeyword = keyword?.toLowerCase();

  if (normalisedKeyword) {
    filters.push([
      {
        field: "tag",
        type: "like",
        value: normalisedKeyword,
      },
      {
        field: "title",
        type: "like",
        value: normalisedKeyword,
      },
    ]);
  }
  // return allResources.filter((resource) => {
  //   return (
  //     resource.tag.toLowerCase().includes(normalisedKeyword) ||
  //     resource.title.toLowerCase().includes(normalisedKeyword)
  //   );
  // });

  return filterList(allResources, filters);
};

export const createLabelForResource = (resourceType) => {
  return resourceTypeLabels[resourceType] || "Resource";
};

const cleanResource = (resourceData) => {
  return removeProps(resourceData, RESOURCE_PROPS_TO_REMOVE);
};

export const cleanResources = (resourcesToClean) => {
  if (!Array.isArray(resourcesToClean)) resourcesToClean = [resourcesToClean];
  const clonedResources = cloneDeep(resourcesToClean);
  return clonedResources.map((n) => cleanResource(n));
};

export const createRestoredDataOfResource = (
  resourceDataToRestore,
  allCategories = store.getters["resourcesData/categories"]
) => {
  const updates = {};

  const propsToRestore = Object.keys(resourceDataToRestore);

  propsToRestore.forEach((prop) => {
    if (RESOURCE_PROPS_TO_RESTORE.indexOf(prop) >= 0) {
      if (prop === "parentCatKey") {
        if (
          resourceDataToRestore[prop] &&
          allCategories[resourceDataToRestore[prop]]
        ) {
          updates[prop] = allCategories[resourceDataToRestore[prop]].key;
        } else {
          updates[prop] = "";
        }
      } else if (prop === "order") {
        if (typeof resourceDataToRestore[prop] === "number") {
          updates[prop] = resourceDataToRestore[prop];
        }
      } else {
        updates[prop] = resourceDataToRestore[prop] || "";
      }
    }
  });

  updates.modified = getCurrDate();

  return updates;
};

export const getAllMentionedResourceIdsFromText = (text, convert = true) => {
  let mentionedIDs = [];

  if (!isEmpty(text)) {
    if (convert) {
      text = convertHTMLToQlDelta(text, qlInstance);
    }

    const processedDelta = text.ops;

    if (!isEmpty(processedDelta)) {
      mentionedIDs = processedDelta.reduce((acc, d) => {
        if (
          typeof d.insert === "object" &&
          !isEmpty(d.insert.resourceMention) &&
          !acc.includes(d.insert.resourceMention.id)
        ) {
          acc.push(d.insert.resourceMention.id);
        }

        return acc;
      }, []);
    }
  }

  return mentionedIDs;
};

export const getResourceDataByTag = (tag) => {
  const allResourcesList = getUserResources().getResources("list");

  return allResourcesList.find((r) => r.tag === tag);
};

export const getAllResourcesIdsByTags = (tags, parse = true) => {
  return tags.reduce((accu, t) => {
    const resourceData = getResourceDataByTag(parse ? t.substring(1) : t);

    if (!isEmpty(resourceData)) {
      accu.push(resourceData.key);
    }
    return accu;
  }, []);
};

const resourceSortOrder = {
  project: 1,
  people: 2,
  place: 3,
  thing: 4,
};
export const getAllResourcesByIds = (resourcesIds) => {
  let list = [];
  const allResourcesMap = store.getters["resourcesData/rawResourcesMap"] || {};
  if (!isEmpty(resourcesIds)) {
    list = resourcesIds.reduce((accu, rId) => {
      if (allResourcesMap[rId]) {
        accu.push({
          ...allResourcesMap[rId],
        });
      }
      return accu;
    }, []);
  }

  list.sort((a, b) => resourceSortOrder[a.type] - resourceSortOrder[b.type]);

  return list;
};

export const getResourceMentionStyleConfig = () => {
  return {
    displayType: store.getters["resourceSettings"]?.displayType ?? "default",
    showUnderlineOnHover:
      store.getters["resourceSettings"]?.showUnderlineOnHover ?? true,
  };
};

export const getResourceMentionStyleClassNameBasedOnType = (type) => {
  return resourceDisplayOptsMap[type].styleClassName;
};

export const getAllResourceCateParentIds = (
  cateKey,
  allCategories = store.getters["resourcesData/categories"]
) => {
  const ids = [];

  if (allCategories[cateKey]) {
    ids.unshift(cateKey);
    ids.unshift(...getParentCateIds(cateKey, allCategories));
  }
  return ids;
};

const getParentCateIds = (cateKeyForParent, allCategories) => {
  let rtn = [];
  if (allCategories[cateKeyForParent].parentCatKey) {
    if (allCategories[allCategories[cateKeyForParent].parentCatKey]) {
      rtn.unshift(
        allCategories[allCategories[cateKeyForParent].parentCatKey].key
      );
      rtn.unshift(
        ...getParentCateIds(
          allCategories[cateKeyForParent].parentCatKey,
          allCategories
        )
      );
    }
  }
  return rtn;
};

export const setResourceIdInTree = async (resourceData) => {
  const resourceType = resourceData.type;
  const userResources = getUserResources();
  let currSelectedCategories = userResources.getSelectedCategories();

  if (isEmpty(currSelectedCategories)) {
    currSelectedCategories = {};
  }

  const fieldUpdates = [];

  if (resourceData.type !== "project") {
    fieldUpdates.push({
      key: "selectedResourceType",
      value: resourceType,
    });
  }

  fieldUpdates.push({
    key: "selectedCategories",
    value: {
      ...currSelectedCategories,
      [resourceType]: [resourceData.key],
    },
  });

  await getUserResources().setMultiFields([...fieldUpdates]);

  return true;
};

export const expandAllParentsOfResource = (resourceData) => {
  const catTreeView = getResourceTreeView("active").treeInstance;
  // const inactiveTree = getResourceTreeView("inactive").treeInstance;
  // const catTreeView = document.querySelector("#resources-categories-tree")
  //   .ej2_instances[0];
  if (catTreeView) {
    let allParentIds = ["root"];
    const parentCatId = resourceData.catId;

    if (parentCatId) {
      allParentIds = getAllResourceCateParentIds(parentCatId);
    }

    setTimeout(() => {
      catTreeView.expandAll(allParentIds);
      // inactiveTree?.expandAll(allParentIds);

      if (resourceData.key) {
        catTreeView.ensureVisible(resourceData.key);
      }
    }, 0);
  }
};

export const checkIfSelectedResourceCateHasResourcesOnly = (
  resourceCateList
) => {
  const allResourcesMap = store.getters["resourcesData/rawResourcesMap"] || {};
  return resourceCateList.every((r) => !isEmpty(allResourcesMap[r]));
};

export const checkIfSelectedResourceCateHasCategoryOnly = (
  resourceCateList
) => {
  const allCategoriesMap = store.getters["resourcesData/categories"] || {};
  const allResourcesMap = store.getters["resourcesData/rawResourcesMap"] || {};
  return resourceCateList.every(
    (r) => isEmpty(allResourcesMap[r]) && !isEmpty(allCategoriesMap[r])
  );
};

export const storeResourceEditAction = (dataList, actionType) => {
  dataList = convertValueToArray(dataList);

  if (!actionType) {
    actionType = RESOURCE_ACTIONS.BATCH_EDIT;
  }
  const actionToStore = actionType;
  getUserActions().addResourceAction({
    data: dataList,
    type: actionToStore,
  });
};

export const extractEditedDataFromResourcesUpdate = (dataList) => {
  let editedData = [];

  dataList.forEach((d) => {
    if (!isEmpty(d) && !isEmpty(d.editedData)) {
      editedData.push({
        ...d.editedData,
        key: d.key,
      });
    }
  });

  return editedData;
};

export const getResourceTreeView = () => {
  // type = "active";
  // const isShowClearedTasksEnabled = store.getters["task/showClearedTasks"];

  let treeElement;
  let targetId;
  // if (type === "active") {
  //   const treeTarget = isShowClearedTasksEnabled
  //     ? "#resources-categories-all-data-tree"
  //     : "#resources-categories-tree";

  //   targetId = treeTarget;
  //   treeElement = document.querySelector(treeTarget);
  // } else if (type === "inactive") {
  //   const treeTarget = isShowClearedTasksEnabled
  //     ? "resources-categories-tree"
  //     : "#resources-categories-all-data-tree";
  //   targetId = treeTarget;
  //   treeElement = document.querySelector(treeTarget);
  // }

  const treeTarget = "#resources-categories-tree";

  targetId = treeTarget;
  treeElement = document.querySelector(treeTarget);
  return {
    element: treeElement,
    treeInstance: treeElement?.ej2_instances[0],
    targetId,
  };
};

const getResourcesRecursive = (resourceCateData, result) => {
  const childrenProp = "children";

  if (Array.isArray(resourceCateData)) {
    resourceCateData.forEach((item) => {
      if (item.isResource) {
        result.push({ ...item });
      }
      getResourcesRecursive(item[childrenProp], result);
    });
  } else if (isPlainObject(resourceCateData) && resourceCateData.children) {
    getResourcesRecursive(resourceCateData[childrenProp], result);
  }
};
export const getAllResourcesOfResourceCate = (resourceCateData) => {
  const resources = [];
  if (!isEmpty(resourceCateData)) {
    getResourcesRecursive(resourceCateData, resources);
  }

  return resources;
};

export const checkIfResourceDescrIsEmpty = (resourceDescr) => {
  return emptyDescrTypes.includes(resourceDescr);
};

/**
 * Get used tags by resource type
 * @param {{[string]:*}} usedTagsMap Map of used tags by resource type
 * @param {string} resourceType Type of resource
 * @returns {string[]} List of tags by resource type
 */
export const getUsedTagsByResourceType = (usedTagsMap, resourceType) => {
  if (!usedTagsMap[resourceType]) {
    usedTagsMap[resourceType] = [];
  }

  return usedTagsMap[resourceType];
};

/**
 * Get resource view task related filtered map
 * @returns {{[string]:*}} Map of all resource view filters
 */
export const getResourceViewTaskFilters = () => {
  const additionalTaskFilters = store.getters["task/additionalTaskFilters"];
  const clonedAdditionalFilters = cloneDeep(additionalTaskFilters);
  delete clonedAdditionalFilters.resourceTypes;

  // clonedAdditionalFilters.filterResources = this.filterResourcesList || [];

  return clonedAdditionalFilters;
};

/**
 * Get resources by resource ids and filter out resources
 * @param {string[]} resourceIds List of resource ids
 * @param {Array<(resourceData:{[string]:*}) => boolean>} filterFuncs List of filter functions to filter out resources
 * @returns {Array<{[string]:*}>}
 */
export const getResourcesByResourceIds = (resourceIds, filterFuncs) => {
  const allResourcesMap = getUserResources().getResources();
  return resourceIds.reduce((accu, resourceId) => {
    const resourceData = allResourcesMap[resourceId];

    if (!isEmpty(resourceData)) {
      let storeInList = true;

      if (filterFuncs && !filterFuncs.every((f) => f(resourceData))) {
        storeInList = false;
      }

      if (storeInList) {
        accu.push(resourceData);
      }
    }
    return accu;
  }, []);
};

const addResourcesInResourcesMap = (
  resourcesToAdd,
  resourcesMap,
  currUsedTags
) => {
  return resourcesToAdd.reduce((dataMap, r) => {
    if (!dataMap[r.key]) {
      dataMap[r.key] = r;
      const currTagList = getUsedTagsByResourceType(currUsedTags, r.type);
      addTagAndRemoveOldTag(r.tag, undefined, currTagList);
    }
    return dataMap;
  }, resourcesMap);
};

const addTagAndRemoveOldTag = (tagToAdd, tagToRemove, currList) => {
  const normizedTagToAdd = tagToAdd && tagToAdd.trim();
  const normizedTagToRemove = tagToRemove && tagToRemove.trim();
  if (normizedTagToAdd) {
    if (!currList.includes(normizedTagToAdd)) {
      currList.push(normizedTagToAdd);
    }
  }

  if (normizedTagToRemove) {
    const currIndex = currList.indexOf(normizedTagToRemove);
    if (currIndex >= 0) {
      currList.splice(currIndex, 1);
    }
  }
};

const updateResourcesInResourcesMap = (
  resourcesToUpdate,
  resourcesMap,
  currUsedTags
) => {
  const finalMap = resourcesToUpdate.reduce((dataMap, r) => {
    if (dataMap[r.key]) {
      let checkAndAddTag = false;
      let resourceTypeChanged = false;
      // const resourceType = dataMap[r.key].type;
      const currResourceType = dataMap[r.key].type;
      const currUsedTag = dataMap[r.key].tag && dataMap[r.key].tag.trim();
      const updatedTag = r.updates.tag && r.updates.tag.trim();
      dataMap[r.key] = {
        ...dataMap[r.key],
        ...r.updates,
        key: r.key,
      };

      const updatedResourceType = dataMap[r.key].type;
      const updatedResourceTag =
        dataMap[r.key].tag && dataMap[r.key].tag.trim();
      if (updatedResourceType !== currResourceType) {
        resourceTypeChanged = true;
      } else if (!currUsedTag && updatedTag) {
        checkAndAddTag = true;
      } else if (currUsedTag && updatedTag && currUsedTag !== updatedTag) {
        checkAndAddTag = true;
      }

      if (checkAndAddTag) {
        const currTagList = getUsedTagsByResourceType(
          currUsedTags,
          currResourceType
        );
        addTagAndRemoveOldTag(updatedTag, currUsedTag, currTagList);
      }
      if (resourceTypeChanged) {
        const currTypeUsedTags = getUsedTagsByResourceType(
          currUsedTags,
          currResourceType
        );

        const newTypeUsedTags = getUsedTagsByResourceType(
          currUsedTags,
          updatedResourceType
        );
        addTagAndRemoveOldTag(undefined, currUsedTag, currTypeUsedTags);
        addTagAndRemoveOldTag(updatedResourceTag, undefined, newTypeUsedTags);
      }
    }
    return dataMap;
  }, resourcesMap);

  return finalMap;
};

const removeResourcesFromResourcesMap = (
  resourcesToRemove,
  resourcesMap,
  currUsedTags
) => {
  return resourcesToRemove.reduce((dataMap, r) => {
    if (dataMap[r.key]) {
      const resourceType = dataMap[r.key].type;
      delete dataMap[r.key];

      const currTagList = getUsedTagsByResourceType(currUsedTags, resourceType);
      addTagAndRemoveOldTag(undefined, r.tag, currTagList);
    }
    return dataMap;
  }, resourcesMap);
};

export const addOrRemoveOrUpdateResourcesInList = async (
  { resourcesToAdd, resourcesToUpdate, resourcesToRemove },
  recordAction = true
) => {
  resourcesToAdd = convertValueToArray(resourcesToAdd);
  resourcesToRemove = convertValueToArray(resourcesToRemove);
  resourcesToUpdate = convertValueToArray(resourcesToUpdate);
  const userResourcesData = await getUserResources();
  const currDbResources = userResourcesData.getResources();
  const currDbUsedTagsMap = userResourcesData.getUsedTags();
  let currRawResources = !isEmpty(currDbResources)
    ? cloneDeep(currDbResources)
    : {};

  const currUsedTags = !isEmpty(currDbUsedTagsMap)
    ? cloneDeep(currDbUsedTagsMap)
    : {};
  let actionToStore, actionData;
  if (resourcesToAdd && resourcesToAdd.length) {
    currRawResources = addResourcesInResourcesMap(
      resourcesToAdd,
      currRawResources,
      currUsedTags
    );
    actionToStore = RESOURCE_ACTIONS.BATCH_ADD;
    actionData = [...resourcesToAdd];
  }

  if (resourcesToUpdate && resourcesToUpdate.length) {
    actionToStore = RESOURCE_ACTIONS.BATCH_EDIT;
    actionData = extractEditedDataFromResourcesUpdate(resourcesToUpdate);

    currRawResources = updateResourcesInResourcesMap(
      resourcesToUpdate,
      currRawResources,
      currUsedTags,
      actionData
    );
  }

  if (resourcesToRemove && resourcesToRemove.length) {
    currRawResources = removeResourcesFromResourcesMap(
      resourcesToRemove,
      currRawResources,
      currUsedTags
    );
    actionToStore = RESOURCE_ACTIONS.BATCH_DELETE;
    actionData = cleanResources(resourcesToRemove);
  }

  await userResourcesData.setMultiFields([
    {
      key: "resources",
      value: currRawResources,
    },

    {
      key: "usedTagsMap",
      value: currUsedTags,
    },
    // {
    //   key: "usedTags",
    //   value: currUsedTags,
    // },
  ]);

  if (recordAction && actionToStore) {
    getUserActions().addResourceAction({
      data: actionData,
      type: actionToStore,
    });
  }

  return true;
};

/**
 * Params for a resource edit
 * @typedef ResourceEditData
 * @type {object}
 * @property {{[string]:*}[]} resources - List of resources
 * @property {boolean} catId - Id of category
 * @property  {boolean} isTaskRef - Category is a task area key or not
 */

/**
 * Change parent category of project resources
 * @param { ResourceEditData[]} list - List of resource parms data

 */
export const changeCatOfProjectResources = async (list) => {
  const allResourceCateList = getUserResources().getCategories("list");
  const updatesList = [];

  list.forEach(({ catId, resources, isTaskRef }) => {
    let catIdToUse = catId;

    if (isTaskRef) {
      const resourceCateData = findResourceCateDataByRef(
        allResourceCateList,
        catId,
        "project"
      );

      if (!isEmpty(resourceCateData)) {
        catIdToUse = resourceCateData.key;
      }
    }

    resources.forEach((n) => {
      if (n.type === "project") {
        const { updates, editedData } = createUpdateAndEditedResourceDataByCell(
          {
            fieldName: "catId",
            currValue: catIdToUse || "",
            rowId: n.key,
            oldValue: n.catId,
            currRowData: n,
          }
        );
        updatesList.push({ updates, editedData, key: n.key });
      }
    });
  });

  addOrRemoveOrUpdateResourcesInList(
    {
      resourcesToUpdate: updatesList,
    },
    false
  );
  addOrUpdateOrRemoveResourcesInLocalList({
    resourcesToUpdate: updatesList,
  });

  return true;
};

/**
 * Get mentioned project resources from text
 * @param {string} text Text for extracting resources
 * @returns {{[string]:*}[]}
 */
export const getProjectResourcesFromText = (text) => {
  const resourcesList = getAllMentionedResourceIdsFromText(text);
  const projectResources = getResourcesByResourceIds(resourcesList, [
    (resourceData) => {
      return resourceData.type === "project";
    },
  ]);

  return projectResources;
};

/**
 * Create filter to get tasks without project resource
 * @param {string} resourceType Resource type to ignore
 * @returns {{[string]:*}}
 */
export const createIgnoreResourceTypeFilter = (resourceType) => {
  return {
    field: "resourceTypes",
    type: "excludes",
    value: resourceType,
  };
};

const getNoProjectNodesRecursive = (resourceCateData, nodesList) => {
  const childrenProp = "children";

  if (Array.isArray(resourceCateData)) {
    resourceCateData.forEach((item) => {
      if (item.isNoProjectNode) {
        nodesList.push({ ...item });
      }
      getNoProjectNodesRecursive(item[childrenProp], nodesList);
    });
  } else if (isPlainObject(resourceCateData) && resourceCateData.children) {
    getNoProjectNodesRecursive(resourceCateData[childrenProp], nodesList);
  }
};
export const getAllNoProjectNodesOfResourceCate = (resourceCateData) => {
  const nodeList = [];
  if (!isEmpty(resourceCateData)) {
    getNoProjectNodesRecursive(resourceCateData, nodeList);
  }

  return nodeList;
};
